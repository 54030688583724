html,
* {
	box-sizing: border-box;
}

body {
	overflow: hidden;
	position: fixed;
	overscroll-behavior: none;
	font-family: sans-serif;
	text-align: center;
	color: white;
	padding: 0;
	margin: 0;
	background: black;
	color: white;
	touch-action: none;
	/* pointer-events: none; */
}

canvas {
	width: 100%;
	height: 100%;
	z-index: 100;
	/* touch-action: none;

	user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none; */
}

.wrapper {
	width: 100%;
	height: 100svh;
	display: flex;
	align-items: center;
	justify-content: center;
}

#root {
	position: fixed;
	width: 100%;
	height: 100svh;

	align-items: center;
	justify-content: center;
}
